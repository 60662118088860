import React from 'react'

import {Link} from 'gatsby'

import {
  EuiImage,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer
} from '@elastic/eui'

import MultiView01 from '../../../../../images/Software/iOS/InstarVision/iPad/Icons/VideoSettings-idle.webp'
import MultiView02 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Ps_Preset_idle-46x46.webp"
import MultiView03 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Motion-off_46x46.webp"
import MultiView04 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Relay-open_46x46.webp"
import MultiView05 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Relay-open_23x23.webp"
import MultiView06 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/AudioDetection_idle_23x23.webp"
import MultiView07 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/PIR_inactive_23x23.webp"
import MultiView08 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Snapshot-idle_46x46.webp"
import MultiView09 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Bulb-active.webp"
import MultiView10 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Speaker-inactive.webp"
import MultiView11 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Mic-inactive.webp"
import MultiView12 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/REC_idle_23x23.webp"
import MultiView13 from "../../../../../images/Software/iOS/InstarVision/iPad/Icons/Push_inactive_23x23.webp"


export default function ImageCard() {

  return (
    <React.Fragment>
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView01}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Image Settings:</strong> This button leads you to the camera&apos;s image settings.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView02}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Save Position:</strong> The Ps is only displayed for cameras that have a pan & tilt function. Long-pressing the button will open a menu with 8 - 15 (depending on the camera model) positions. Selecting a number from this menu will save the cameras position which can later be recalled by the P button in the middle of the pan & tilt control.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView03}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Motion Detection:</strong> The little burglar symbol activates or deactivates the cameras motion detection. The motion detection areas and alarm actions have to be set inside the camera&apos;s web user interface to be switched on and off by the Motion Detection button.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView04}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Alarm Input:</strong> Switch the state of your alarm input between open and close. This button does not deactivate your alarm input!
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      
      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView05}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Alarm Output Relay:</strong> The Relay Button - the two vertical lines with the switch in the middle - opens or closes the cameras relay. External devices connected to the camera&apos;s Alarm-out can be switched remotely by pressing this button.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView06}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Audio Detection:</strong> Let your camera listen to its environment and trigger an alert in case of a loud noise. Your camera needs a microphone to be able to use this functions.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView07}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>PIR Sensor:</strong> Activate the internal heat sensor of your camera (e.g. <Link to="/Indoor_Cameras/IN-6014_HD/">IN-6014 HD</Link>, <Link to="/Indoor_Cameras/IN-8015_HD/">IN-8015 Full HD</Link> or <Link to="/Outdoor_Cameras/IN-9008_HD/">IN-9008 Full HD</Link>) for motion detection.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView08}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Snapshot:</strong> The camera button will take a snapshot of the camera&apos;s live image and store it in your cell phones photo library.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView09}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>IR LEDs:</strong> The IR Switch allows you to switch the camera&apos;s infrared LEDs on or off (when this function is supported by the camera).
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView10}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Audio:</strong> The Audio Switch allows you to switch the camera&apos;s audio stream on or off (when this function is supported by the camera).
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView11}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Microphone:</strong> Use the Microphone Switch to send an audio stream from your mobile devices microphone to the camera (only works for camera&apos;s with integrated or attached speakers).
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView12}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Manual Recording:</strong> Start a recording of your camera&apos;s video stream on your smartphone or on your IP camera&apos;s internal SD card (<Link to="/Web_User_Interface/720p_Series/Alarm/SD_Card/">720p</Link> / <Link to="/Web_User_Interface/1080p_Series/Features/SD_Card/">1080p</Link> Camera Series).
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
        <EuiFlexItem grow={false}>
          <EuiImage
            hasShadow
            allowFullScreen
            alt="InstarVision Android App"
            src={MultiView13}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <p>
              <strong>Push Notification:</strong> Just click this button and scan your camera&apos;s Push QR Code (<Link to="/Web_User_Interface/720p_Series/Network/Push_Service/">720p</Link> / <Link to="/Web_User_Interface/1080p_Series/Alarm/Push_Service/">1080p</Link> Camera Series) to receive push notifications in case of an alarm event.
            </p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </React.Fragment>
  );
}